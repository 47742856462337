<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordionType = accordionType === 0 ? false : 0"
      >
        <h5 class="m-0">Detalle de servicio</h5>
      </CButton>
      <CCollapse :show="accordionType === 0">
        <CCardBody>
          <b-row>           
            <!-- Service -->
            <b-col lg="6">
              <b-form-group>
                <strong>Servicio *</strong>
                <v-select
                  :options="getServicesNames()"
                  v-model="payment.serviceSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid':
                      $v.payment.serviceSelected.$error,
                    'form-control is-valid': !$v.payment.serviceSelected
                      .$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.serviceSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Type -->
            <b-col lg="6">
              <b-form-group>
                <strong>Tipo *</strong>
                <v-select
                  :options="typeOptions"
                  v-model="payment.typeSelected"
                  placeholder="Selecciona"
                  :class="{
                    'form-control is-invalid': $v.payment.typeSelected.$error,
                    'form-control is-valid': !$v.payment.typeSelected.$invalid
                  }"
                >
                </v-select>
                <b-form-invalid-feedback
                  v-if="!$v.payment.typeSelected.required"
                >
                  Debes seleccionar un servicio
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- Quantity -->
            <b-col sm="4">
              <b-form-group>
                <strong>Ctdad. de servicios *</strong>
                <b-form-input
                  id="quantity"
                  type="number"
                  min="1"
                  placeholder=""
                  disabled
                  v-model.trim="$v.payment.quantity.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.quantity.$error,
                    'form-control is-valid': !$v.payment.quantity.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.quantity.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>

            <!-- pets -->
            <b-col sm="4">
              <b-form-group>
                <strong>Ctdad. de Mascotas *</strong>
                <b-form-input
                  id="pets"
                  type="number"
                  min="1"
                  placeholder=""
                  v-model.trim="$v.payment.pets.$model"
                  :class="{
                    'form-control is-invalid': $v.payment.pets.$error,
                    'form-control is-valid': !$v.payment.pets.$invalid
                  }"
                >
                </b-form-input>
                <b-form-invalid-feedback v-if="!$v.payment.pets.required">
                  Debes indicar la ctdad de servicios.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>


            <!-- Plan price filtered -->
            <b-col sm="4">
              <b-form-group v-if="planInfo[0] != null">
                <strong>Precio und:</strong>
                <b-form-input
                  type="number"
                  id="quantity"
                  min="0"
                  placeholder=""
                  v-model="planInfo[0].precio"
                >
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <br>
          </b-row>
          <b-row>
            <b-col lg="6">
              
              <b-button @click="submit">
                Agregar
              </b-button>              
            </b-col>
            <b-col lg="6" v-if="flagBtnShow">
              <b-button @click="showServicesFixed">
                Ver servicios
              </b-button>
            </b-col>
          </b-row>
        </CCardBody>
      </CCollapse>

     
    </CCard>
  </div>
</template>

<script>
import moment from "moment";
import { required } from "vuelidate/lib/validators";
import { uuid } from "uuidv4";
import firebase from 'firebase';
export default {
  name: "paymentHeader",
  props: ["plans", "payer", "clientSchedule", "collectionFee"],
  data() {
    return {
      accordionHeader: 0,
      accordionType: 0,
      //Err
      flagErr: false,
      errMsg: "",
      //Header
      uuidValue: null,
      
      payment: {
        serviceSelected: null,
        typeSelected: null,
        quantity: null, //number of services
        pets: 1,
        collectionFee: 0,
      },
      //Type of service
      servicesOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Paseo" },
        { value: 2, label: "Adiestramiento" },
        { value: 35, label: "Guardería" },
        { value: 68, label: "Conducta" }
      ],
      typeOptions: [
        { value: null, label: "SELECCIONE" },
        { value: 1, label: "Grupal" },
        { value: 2, label: "Dedicado" }
      ],
      order: [],
      flagBtnShow: false
    };
  },
  created() {
    this.uuidValue = uuid();
  },
  validations: {
    payment: {
      serviceSelected: {
        required
      },
      typeSelected: {
        required
      },
      pets: {
        required
      },
      quantity: {
        required
      },
      collectionFee: {},
    }
  },
  watch: {
    // Observar cambios en clientSchedule y payment.quantity
    'clientSchedule': {
      immediate: true,
      handler(newSchedule) {
        if (newSchedule && newSchedule.type === 'specific') {
          this.payment.quantity = newSchedule.dates.length;
        }
      }
    }
  },
  computed: {
    planInfo() {
      let service = this.payment.serviceSelected;
      let quantity = this.payment.quantity; //num of Services
      let pets = this.payment.pets;
      let type = this.payment.typeSelected;
      if (!this.$v.payment.$invalid) {
        this.$store.commit("defPayment", this.payment);

        let plansFiltered = this.plans.filter(
          item =>
            item.servicio == service.label &&
            +item.mascotas == pets &&
            +item.horas_min <= quantity &&
            +item.horas_max >= quantity &&
            item.tipo == type.label
        );

        return plansFiltered;
      } else {
        this.$v.$touch();
        return "Invalido";
      }
    },
  },
  methods: {
    onContext(ctx) {
      // The date formatted in the locale, or the `label-no-date-selected` string
      this.formatted = ctx.selectedFormatted;
      // The following will be an empty string until a valid date is entered
      this.selected = ctx.selectedYMD;
    },
    submit() {
      this.$v.$touch();
      if (this.$v.payment.$invalid) {
        this.errMsg = "Completa los del servicio";
        this.flagErr = true;
        this.order = [];
        this.$store.commit("defOrder", this.order);
      } 
      else {
        this.flagErr = false;
        this.createOrderItem();
      }
    },
    async createOrderItem() {
      let itemOrder = {
        pedido: this.setPedido(),
        servicio: this.setServices()
      }

      this.order.push(itemOrder);

      this.$store.commit("defRequestedService", itemOrder.pedido);
      this.$store.commit("defOrder", this.order);
      this.$store.commit("defShowOrderModal", true);
    },
    setPedido(){
      let pedido = {
        key: this.getKey(),
        articulo_id: this.getArticuloId(),
        cantidad: +this.payment.quantity,
        moneda: "COP",
        precio_und: +this.planInfo[0].precio,
        precio: Number(this.planInfo[0].precio) * Number(this.payment.quantity),

        nombre: this.payer.clientName,
        email: this.payer.client.email,
        cliente: {
          id: this.payer.client.id
        },
        mascota: {
          id: this.payer.id
        },
        
        servicios_restantes: +this.payment.quantity,
        fecha_caducidad: moment(new Date())
          .add('days', this.planInfo[0].caducidad)
          .format("yyyy-MM-DD") + "T00:00:00.000Z",
        planes: {id: this.planInfo[0].id},
        pago_ids: [],
      }
      return pedido
    },
    setServices() {
      let services = []
      const dates = this.clientSchedule.dates;
      for (let index = 0; index < dates.length; index++) {
        const { startDateTime, endDateTime, walker} = dates[index]
        let service = {
          key: this.getKey() + index,
          mascota: { id: this.payer.id },
          obs_cliente: this.setServiceObservation(index),
          fecha_inicio: startDateTime,
          fecha_fin: endDateTime
        };

        if (walker) {
          service.usuario = {
            id: walker.value,
            fullname: walker.label
          }
        }
        services.push(service);
      }
      return services
    },
    setScheduledServices(payload){
      return new Promise((resolve) => {
        let servicesFixSchedule = this.$firebase
            .functions()
            .httpsCallable("servicesFixSchedule");

        servicesFixSchedule(payload).then(res => {
          const servicesFixed = res.data.data;
          resolve(servicesFixed)        
        }).catch(error => {
          console.log("Err setting services with scheduled: ", error);
          resolve(null)
        });
      })
    },
    getKey(){
      const date = new Date();
      const key = "696_17839_" + date.getTime();
      return key
    },
    getArticuloId(){
      return this.payment.serviceSelected.label + " " + this.planInfo[0].tipo
    },
    setServiceObservation(index) {
      const text =  
        `${this.payment.serviceSelected.label} ${index+1}/${this.payment.quantity},`
      return text
    },
    showServicesFixed() {
      this.$store.commit("defShowOrderModal", true);
    },
    
    getPaymentDates(date) {
      let dates = {
        year: moment(date).format('YYYY'),
        month: moment(date).format('MMMM'),
        day: moment(date).format('DD'),
      };
      return dates
    },
    createInvoiceImg(year,month, fileName, file){
      return new Promise((resolve, reject) => {
        let fileTask = firebase.storage().ref(`invoices/${year}/${month}/${fileName}`).put(file)
        fileTask.on(firebase.storage.TaskEvent.STATE_CHANGED, function(snapshot) {
          var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        } , function(error) {
          reject(error)
        }, async function() {
          let downloadURL = await fileTask.snapshot.ref.getDownloadURL()
            // Upload completed successfully, now we can get the download URL
          resolve(downloadURL)
        });
      })
    },
    getServicesNames(){
      const uniqueServices = [...new Set(
        this.plans
          .filter(plan => 
            !['Placa Qr', 'Tarifa', 'Comisión'].includes(plan.servicio)
          )
          .map(plan => plan.servicio)
      )];

      const servicesOptions = [
        { value: null, label: "SELECCIONE" },
        ...uniqueServices.map(service => {
          const plan = this.plans.find(p => p.servicio === service);
          return {
            value: plan.id,
            label: service
          };
        })
      ];
      return servicesOptions;
    }
  }
};
</script>

<style lang="scss" scoped></style>
