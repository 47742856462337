<template>
  <div>
    <loading
      :active.sync="loading"
      :can-cancel="false"
      background-color="#fff"
      :is-full-page="true"
    >
    </loading>
    <b-alert 
      v-model="flagErr" 
      variant="danger" 
      dismissible>
        {{ errMsg }}
    </b-alert>

    <b-row>
      <b-col>
        <ClientInfo :payer="payer" />
        <Schedule :clientId="payer.client.id"/>
      </b-col>
      <b-col>
        <requested-service
          :plans="plans"
          :payer="payer"
          :clientSchedule="clientSchedule"
          :collectionFee="collectionFee"/>
       
        <Modal :showOrderModal="showOrderModal"/>

        <ClientCredit/>
         <payment          
          :plans="plans"
          :payer="payer"
          :clientSchedule="clientSchedule"
          :collectionFee="collectionFee"/>
      </b-col>
    
      <b-col v-if="order.length > 0" lg="3">
         <Resumen          
          :payment="payment"
          :orderResumen="orderResumen"
          :credit="credit"
        />
        <br />
        <b-button           
          variant="warning" 
          class="w-100"
          @click="createOrderRequest"
          v-if="enablePayment">
            CREAR PEDIDO 
        </b-button>
      </b-col>
    </b-row>

    <!-- Modal confirmation -->
    <section>
      <b-modal
        title="Resultado de operación"
        v-model="showAlertModal"
        no-close-on-backdrop
        hide-header-close
        size="md"
      >
        <b-row>
          <b-col>
            <div v-for="msg in alertMsgs" v-bind:key="msg">
              {{msg}}
            </div>
          </b-col>
        </b-row>
        <template #modal-footer>
          <div class="w-100">
            <b-row>           
              <b-col lg="6">
                <b-button 
                  size="md" 
                  variant="warning" 
                  @click="goTo('payments')"
                  >
                  Aceptar
                </b-button>
              </b-col>
            </b-row>
          </div>
        </template>
      </b-modal>
    </section>
  </div>
</template>

<script>
import firebase from "firebase";
import Loading from "vue-loading-overlay";
import _ from 'lodash'

import { servicePaymentMixin } from "../../../mixins/servicePayment";
import ClientInfo from "./payment_info/ClientInfo";
import ClientCredit from "./payment_info/ClientCredit";
import Header from "./payment_info/Header";
import Payment from "./payment_info/Payment.vue";
import RequestedService from "./payment_info/RequestedService";
import Schedule from "./payment_info/Schedule";
import Resumen from "./payment_info/Resumen";
import Modal from "./payment_info/Modal"
const PENDING_PAYMENT_STATE = 1
const RECEIVED_PAYMENT_STATE = 2

export default {
  name: "payment_create",
  components: {
    Loading,
    ClientInfo,
    ClientCredit,
    Header,
    Payment,
    RequestedService,
    Schedule,
    Resumen,
    Modal,
  },
  mixins: [servicePaymentMixin],
  created() {
    this.payer = this.$store.getters.paymentBy;
    this.activePayment = false

    if(!this.payer) 
      this.$router.push({ path: "/financial/payments" });
    this.watch();
    this.fetchWalkers();
    this.fetchClientAndCredit();
    this.fetchPlans();
  },
  methods: {
    watch() {
      this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defPayment") {
          this.payment = this.$store.getters.payment;
        }
       
        if (mutation.type === "defClientSchedule") {
          this.clientSchedule = this.$store.getters.clientSchedule;
          this.clientSchedule.id = this.clientSchedule.id == undefined ? null : this.clientSchedule.id
        }
        if (mutation.type === "defOrder") {
          this.order = this.$store.getters.order;
        }
        if (mutation.type === "defShowOrderModal") {
          this.showOrderModal = this.$store.getters.showOrderModal;
        }
        if (mutation.type === "defClientCreditsToApply") {
          this.clientCreditsToApply = this.$store.getters.clientCreditsToApply;
        }
        if (mutation.type === "defPaymentDTO") {
          this.paymentDTO = this.$store.getters.paymentDTO;
        }
        if(mutation.type === "defPaymentResumen") {
          this.paymentResumen = this.$store.getters.paymentResumen;
          this.enablePayment = true
        }
        if(mutation.type === "defBlockPayment") {
          this.enablePayment = !this.$store.getters.blockPayment;        
        }
      });
    },
    fetchWalkers(){
      let payload = {
        value: this.payer.cities.id,
        label: this.payer.cities.nombre,
      }
      this.$store.dispatch("fetchWalkersByCityId", payload).then(walkers => {
        // commit the walkers in store
      }).catch((error) => {
        console.log('Err fetching walkers')
      })
    },
    fetchClientAndCredit() {
      let data = {
        clientId: this.payer.client.id
      };

      let getClientAndCredit = this.$firebase.functions().httpsCallable("getClientAndCredit")
      getClientAndCredit(data)
        .then(response => {
          const { client: clientResponse , credits: creditsResponse} = response.data
          if (clientResponse.success){
            let client = clientResponse.data[0];
            this.payer.client["celular"] = client.celular;
            this.payer.client["email"] = client.email;
            let scheduleLoaded = null
            if(Object.keys(client.horario).length > 0){
              scheduleLoaded = client.horario
              this.loadSchedule(scheduleLoaded)
            }
          }
          if (creditsResponse.success) {
            const credits = creditsResponse.data
            credits.map(credit => credit.aplicar = false)
            this.$store.commit('defClientCredits', credits);
          }
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => (this.loading = false));
    },
    loadSchedule(schedule){
      
      let id = schedule.id
      let duracion = schedule.duracion
      let festivos = schedule.festivos
      let fechas = JSON.parse(schedule.fechas)

      let dates = []

      this.clientSchedule.dates.map((itemSchedule) => {
        let scheduleFound = fechas.find(fecha => fecha.day == itemSchedule.day)
        if (scheduleFound){
          dates.push(scheduleFound)
        } else{
          dates.push(itemSchedule)
        }
      })
      
      this.clientSchedule.id = id
      this.clientSchedule.dates = dates
      this.clientSchedule.holidays = festivos
      this.clientSchedule.length = duracion
      this.$store.commit("defClientSchedule", this.clientSchedule);
    },
    fetchPlans() {
      const payload = {
        tipoTarifa: _.get(this.payer, 'cities.tipo_tarifa[0]', 'a')
      }

      let getPlans = this.$firebase.functions().httpsCallable("getPlansByFeeType");
      getPlans(payload).then(response => {
          this.plans = response.data.data;

          this.collectionFee = this.plans.find(plan => plan.nombre === 'Tarifa de recaudo');
          this.collectionFee = +this.collectionFee.precio;

          this.displacementFee = this.plans.find(plan => plan.nombre === 'Tarifa de desplazamiento');
          this.displacementFee = +this.displacementFee.precio;

          this.exclusivityFee = this.plans.find(plan => plan.nombre === 'Tarifa de exclusividad de paseador');
          this.exclusivityFee = +this.exclusivityFee.precio;

          this.cancellationFee = this.plans.find(plan => plan.nombre === 'Tarifa de cancelación');
          this.cancellationFee = +this.cancellationFee.precio;
        })
        .catch(error => {
          console.log(error);
        });
    },
    async createOrderRequest() {  
      try {
        this.setItemOrderAmountPaid()

        let payload = {
          payment: this.setPaymentValueByState(),
          order: this.order,
          invoice: this.setInvoice(),
          clientId: this.payer.client.id,
          credit: this.paymentResumen.creditWon,
          creditsApplied: this.paymentResumen.creditsApplied
        };
      
        this.alertMsgs = [];
        this.loading = true
        this.scrollToTop()
        // console.log('payload', payload)
        await this.sendCreateServiceOrderRequest(payload)
        
        this.loading = false;        
        this.order = []
        this.scrollToTop();
        this.alertMsgs.push('¡Pedido registrado exitosamente!')
        this.showAlertModal = true;
        
      } catch (error) {
          this.loading = false;
          console.log(error);
      }
    },
    setItemOrderAmountPaid(){
      let amountReceived = this.paymentDTO.valor + this.paymentResumen.amountDiscount;

      this.order.forEach(orderItem => {
        let totalCostItem = orderItem.pedido.precio;

        if(amountReceived > 0) {
          if (amountReceived >= totalCostItem) {
            orderItem.pedido.pagado = totalCostItem
            orderItem.pedido.pedido_estado = {id: RECEIVED_PAYMENT_STATE}
            orderItem.pedido.debe = 0
            amountReceived -= totalCostItem
          } else {
            orderItem.pedido.pagado = amountReceived
            orderItem.pedido.debe = totalCostItem - amountReceived
            orderItem.pedido.pedido_estado = {id: PENDING_PAYMENT_STATE}
            amountReceived = 0
          }
        } else if(amountReceived == 0) {
          orderItem.pedido.pagado = 0
          orderItem.pedido.debe = totalCostItem;
          orderItem.pedido.pedido_estado = {id: PENDING_PAYMENT_STATE};
        }
      });
    },
    setPaymentValueByState() {
      this.paymentDTO.dscto_credito = this.paymentResumen.amountDiscount
      this.paymentDTO.pago_total = 
        this.paymentDTO.valor + this.paymentResumen.amountDiscount

      return +this.paymentDTO.pedido_estado.id === RECEIVED_PAYMENT_STATE ?
        this.paymentDTO : null
    },
    setInvoice() {
      let adminPhone = "";
      let adminEmail = "";
      if (
        this.payer.cities != null &&
        Object.keys(this.payer.cities).length > 0
      ) {
        adminPhone = this.payer.cities.celular;
        let contacts = JSON.parse(this.payer.cities.contactos);
        adminEmail = contacts[0];
      }

      let invoice = {
        total: this.paymentResumen.totalCost,
        montoRecibido: this.paymentDTO.valor,
        debe: this.paymentDTO.debe,
        estado: this.getPaymentStateLabel(this.paymentDTO.pedido_estado.id),
        metodo: this.paymentDTO.medio,
        fechaPago: this.paymentDTO ? this.paymentDTO.fecha_pago : "",
        tarifaRecaudo:
          this.paymentDTO.medio == "Efectivo" ? this.collectionFee : 0,
        sendTo: this.payer.client.email,
        cliente: this.payer.clientName,
        adminCelular: adminPhone,
        adminEmail: adminEmail
      };

      return invoice
    },    
    sendCreateServiceOrderRequest(payload){
      return new Promise((resolve, reject) => {
        let createServiceOrder = this.$firebase.
          functions()
          .httpsCallable("createServiceOrderAndInvoice3");

        createServiceOrder(payload).then(response => {
          resolve(response)
        })
        .catch(error => {
          reject(error)
        });
      })
    },
    getPaymentStateLabel(stateId){
      if(stateId === PENDING_PAYMENT_STATE) return 'Pendiente'
      if(stateId === RECEIVED_PAYMENT_STATE) return 'Recibido'
    },
    
    goTo(to) {
      if(to == 'payments') {
        this.$router.replace({ name: 'financial-dashboard' })
      }
    },
    scrollToTop() {
      window.scrollTo(0, 0);
    },
  }
};
</script>

<style lang="scss" scoped></style>
