<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CButton
        block
        color="link"
        class="text-left shadow-none card-header"
        @click="accordion = accordion === 0 ? false : 0"
      >
        <h5 class="m-0">Horario de cliente</h5>
      </CButton>
      <CCollapse :show="accordion === 0">
        <CCardBody>
          <!-- Configuración común -->
          <b-row>
            <b-col lg="6">
              <b-row>
                <b-col lg="12">
                  <b-form-group>
                    <strong>Duración *</strong>
                    <v-select
                      :options="lengthOpts"
                      v-model.trim="$v.schedule.length.$model"
                      :class="{
                        'form-control is-invalid': $v.schedule.length.$error,
                        'form-control is-valid': !$v.schedule.length.$invalid
                      }"
                      @input="updateScheduleHeader()"
                      :clearable="false"
                                    
                    >
                    </v-select>
                    <b-form-invalid-feedback v-if="!$v.schedule.length.required">
                      Debes seleccionar una duración
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col lg="12">
                  <strong>Hora de inicio:</strong>
                  <v-select
                    v-model="defaultStartTime"
                    :options="times"
                    placeholder="Seleccione hora"
                  />
                </b-col>
              </b-row>
              <br>
              <b-row>
                <b-col lg="12">
                  <strong>Paseador:</strong>
                  <v-select
                    v-if="walkersOpts != null"
                    v-model="defaultWalker"
                    :options="walkersOpts"
                    placeholder="Seleccione"
                  />
                </b-col>
              </b-row>
          </b-col>
          <b-col lg="6" class="text-center">
            <strong>Selecciona las fechas de servicios:</strong>
            <b-calendar
              v-model="selectedDate"
              locale="es"
              class="flex-grow-1"
              @input="handleDateSelection"
              :reset-value="null"
              :min="new Date()"
            />
          </b-col>
        </b-row>

        <hr>
        <!-- Programación semanal -->
        <b-row>
          <b-col lg="12">
            <div class="d-flex">
              <div class="ml-3" style="min-width: 200px;">
                <div v-for="(service, index) in specificServices" :key="index" class="mb-2">
                  <div class="d-flex align-items-center">
                    <span class="mr-2" style="min-width: 30px;">{{ index + 1 }}.</span>
                    <span>{{ formatDate(service.date) }}</span>
                    <div class="d-flex align-items-center ml-2">
                      <v-select
                        v-model="service.start"
                        :options="times"
                        @input="startTimeChanged($event, getOriginalIndex(service))"
                        style="min-width: 100px;"
                      />
                      <span class="mx-2">-</span>
                      <v-select
                        v-model="service.end"
                        :options="times"
                        style="min-width: 100px;"
                      />
                      <v-select
                        v-model="service.walker"
                        :options="walkersOpts"
                        style="min-width: 100px;"
                      />
                    </div>

                    <b-button 
                      size="sm" 
                      variant="danger"
                      class="ml-2"
                      @click="removeSpecificDate(getOriginalIndex(service))"
                    >
                      <i class="fas fa-times"></i>
                    </b-button>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
       
        <b-row>
          <b-col lg="12">
            <b-alert 
              v-model="flagErr" 
              :variant="alertVariant" 
              dismissible>
              {{ errMsg }}
            </b-alert>
          </b-col>
        </b-row>

        </CCardBody>
      </CCollapse>
    </CCard>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import moment from "moment";
moment.locale("es");
import times from "../../../../utils/times"; // Importar el mismo array de times que usa ScheduleDays

export default {
  name: "schedule",
  props:["clientId"],
  data() {
    return {
      flagErr: false,
      errMsg: "",
      alertVariant: "success",
      accordion: 0,
      lengthOpts: [
        { value: 1, label: "45 minutos" },
        { value: 2, label: "1 hora" }
      ],
      schedule: {
        length: { value: 1, label: "45 minutos" },
        holidays: true
      },
      scheduleType: 'specific',
      weekCount: 1,
      startDate: '',
      selectedDate: null,
      specificServices: [],
      times: times,
      defaultStartTime: null,
      defaultWalker: null,
      walkers: null,
      walkersOpts: null,
    };
  },
  validations: {
    schedule: {
      length: {
        required
      }
    }
  },
  computed: {
    // walkersOpts(){
    //   let data = []
    //   if (this.walkers!= null) {
    //     this.walkers.map((walker) => {
    //       let item = {
    //         value: walker.id,
    //         label: walker.fullname,
    //       };
    //       data.push(item)
    //     })
    //     return data
    //   }else{
    //     return null
    //   }
    // }
  },
  created(){
    this.watchs()
  },
  watch: {
    defaultStartTime(newTime) {
      if (!newTime) return;
      
      // Actualizar todas las fechas que no tengan hora de inicio configurada
      this.specificServices.forEach((service, index) => {
        if (!service.start) {
          service.start = newTime;
          this.startTimeChanged(newTime, index);
        }
      });
    },
    scheduleType(newType) {
      // Actualizar el store cuando cambie el tipo de programación
      this.updateScheduleHeader();
    },
    defaultWalker(newWalker) {
      if (!newWalker) return;
      
      this.specificServices.forEach((service, index) => {
        if (!service.walker) {
          service.walker = newWalker;
          this.walkerChanged(newWalker, index);
        }
      });
    },
  },
  methods: {
    watchs() {
      this.$store.subscribe((mutation) => {
        if (mutation.type === "defClientSchedule") {
          let clientSchedule = this.$store.getters.clientSchedule;
          if(clientSchedule != null){
            this.schedule = {
              length: clientSchedule.length,
              holidays: clientSchedule.holidays,
            };
          }
        }
        if (mutation.type === 'defWalkers') {
          this.walkers = this.$store.getters.walkers;
          this.setWalkers()
        }
      })
    },
    setWalkers(){
      let data = []
      if (this.walkers!= null) {
        this.walkers.map((walker) => {
          let item = {
            value: walker.id,
            label: walker.fullname,
          };
          data.push(item)
        })
        // return data
        this.walkersOpts = data
      }
    },
    walkerChanged(walker, index) {
      if (this.specificServices[index]) {
        this.specificServices[index].walker = walker;
        this.sortAndUpdateServices();
      }
    },
    getOriginalIndex(service) {
      return this.specificServices.findIndex(s => 
        s.date === service.date && 
        s.start === service.start && 
        s.end === service.end
      );
    },
    setClientSchedule(){
      let clientSchedule = this.$store.getters.clientSchedule;
      clientSchedule.length = this.schedule.length
      clientSchedule.holidays = this.schedule.holidays
      let payload = {
        clientId: this.clientId,
        clientSchedule
      }
      if(clientSchedule.id != null){
        //  The client has a schedule 
        let updateClientSchedule = this.$firebase.functions().httpsCallable('updateClientSchedule')
        updateClientSchedule(payload).then(() => {
          this.flagErr = true
          this.errMsg = "¡Horario de cliente actualizado!"
        }).catch((error) => {
          console.log('Err updating schedule: ', error)
        })
      }else {
        // Schedule to be created
        let createClientSchedule = this.$firebase.functions().httpsCallable('createClientSchedule')
        createClientSchedule(payload).then((res) => {
          this.flagErr = true
          this.errMsg = "¡Nuevo horario de cliente creado!"
          let data = res.data.data
          clientSchedule.id = +(data.id)
          this.$store.commit("defClientSchedule", clientSchedule)
        }).catch((error) => {
          console.log('Err creating schedule: ', error)
        })
      }
    },
    updateScheduleHeader(){
      let clientSchedule = this.$store.getters.clientSchedule;
      let schedule = {
        length: this.schedule.length != null ? this.schedule.length: null,
        holidays: this.schedule.holidays != null ? this.schedule.holidays : null,
        dates: clientSchedule.dates != null ? clientSchedule.dates : null,
        id: clientSchedule.id != null ? clientSchedule.id : null ,
        type: this.scheduleType,
      };
      this.$store.commit("defClientSchedule", schedule)
    },
    handleDateSelection(date) {
      if (!date) return;

      const startTime = this.defaultStartTime;
      const walker = this.defaultWalker;
      
      if (!startTime) {
        this.specificServices.push({
          date: date,
          start: null,
          end: null,
          walker: walker
        });
        this.sortAndUpdateServices();
        // Resetear la fecha seleccionada
        this.$nextTick(() => {
          this.selectedDate = null;
        });
        return;
      }

      // Verificar si ya existe un servicio en la misma fecha y hora
      const existingService = this.specificServices.find(s => 
        s.date === date && 
        s.start && 
        s.start.value === startTime.value
      );

      if (existingService) {
        this.showError('Ya existe un servicio programado para esta fecha y hora');
        // Resetear la fecha seleccionada incluso si hay error
        this.$nextTick(() => {
          this.selectedDate = null;
        });
        return;
      }
      
      let length = this.schedule.length.value;
      let endTime = null;
      
      if (length == 1) {
        endTime = this.times.find(t => t.value == startTime.value + 3);
      } else if (length == 2) {
        endTime = this.times.find(t => t.value == startTime.value + 4);
      }

      const startDateTime = `${date}T${startTime.label}:00.000Z`;
      const endDateTime = `${date}T${endTime.label}:00.000Z`;
      
      this.specificServices.push({
        date: date,
        start: startTime,
        end: endTime,
        startDateTime: startDateTime,
        endDateTime: endDateTime,
        walker: walker
      });

      this.sortAndUpdateServices();

      // Resetear la fecha seleccionada después de agregar exitosamente
      this.$nextTick(() => {
        this.selectedDate = null;
      });
    },
    // Nuevo método para ordenar y actualizar
    sortAndUpdateServices() {
      // Ordenar el array original
      this.specificServices.sort((a, b) => {
        const dateComparison = new Date(a.date) - new Date(b.date);
        if (dateComparison !== 0) return dateComparison;
        
        const aTime = a.start ? a.start.value : 0;
        const bTime = b.start ? b.start.value : 0;
        return aTime - bTime;
      });

      // Actualizar el store con los servicios ordenados
      this.updateSpecificDatesInStore();
    },
    showError(message) {
      this.flagErr = true;
      this.errMsg = message;
      this.alertVariant = 'danger';
      
      setTimeout(() => {
        this.flagErr = false;
      }, 3000);
    },
    startTimeChanged(time, index) {
      if (!time) return;
      
      let length = this.schedule.length.value;
      let timeFound = null;
      
      if (length == 1) {
        timeFound = this.times.find(t => t.value == time.value + 3);
      } else if (length == 2) {
        timeFound = this.times.find(t => t.value == time.value + 4);
      }
      
      if (this.specificServices[index]) {
        this.specificServices[index].start = time;
        this.specificServices[index].end = timeFound;
      }

      this.sortAndUpdateServices();
    },
    removeSpecificDate(index) {
      this.specificServices.splice(index, 1);
      this.sortAndUpdateServices();
    },
    calculateEndTime(startTime) {
      const duration = this.schedule.length.value === 1 ? 45 : 60; // 45 mins o 1 hora

      return moment(startTime, 'HH:mm')
        .add(duration, 'minutes')
        .format('HH:mm');
    },
    formatDate(date) {
      return moment(date).format('ddd DD/MMM');
    },
    // Nuevo método para actualizar el store con las fechas específicas
    updateSpecificDatesInStore() {
      let clientSchedule = this.$store.getters.clientSchedule;
      let schedule = {
        length: this.schedule.length,
        holidays: this.schedule.holidays,
        dates: this.specificServices,
        id: clientSchedule ? clientSchedule.id : null,
        type: this.scheduleType
      };
      this.$store.commit("defClientSchedule", schedule);
    },
  },
  
};
</script>

<style lang="scss" scoped>
.mb-3 {
  margin-bottom: 1rem;
}
</style>
