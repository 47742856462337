<template>
  <div>
    <CCard class="mb-0" style="margin-top:0.5rem;">
      <CCardHeader color="info">
        <strong>Resumen</strong>
      </CCardHeader>
      <CCardBody >
        <!-- <b-row>
          <b-col>
            Credits To Apply: <br>
            {{clientCreditsToApply2}} <br>
            Credits applied: <br>
            {{creditsApplied}} <br>
            Order: <br>
            {{order}}
          </b-col>
        </b-row> -->
        <b-row>
          <b-col lg="12" v-if="order != null">
            <div v-for="(item, index) in order">
              <b-card bg-variant="secondary">
                <b-col lg="12">
                  <div>
                    <span
                      class="float-right"
                      style="cursor:pointer; color:red"
                      @click="removeElement(index)"                      
                    >
                      X
                    </span>
                  </div>
                  <div>
                    <strong>Servicio:</strong> {{ item.pedido.articulo_id }}
                  </div>
                  <div>
                    <strong>Cantidad servicios:</strong>
                    {{ item.pedido.cantidad }}
                  </div>
                  <div>
                    <strong>A partir de:</strong>
                    {{ item.pedido.fecha_inicio }}
                  </div>
                  <div>
                    <strong>Precio und:</strong>
                    {{ item.pedido.precio_und }}
                  </div>                
                  <div>
                    <strong>Subtotal:</strong>
                    {{item.pedido.precio_und * item.pedido.cantidad}}
                  </div>
                </b-col>
              </b-card>
            </div>           
          </b-col>


          <b-col lg="9" style="text-align: right;">
            Subtotal
          </b-col>
          <b-col lg="3" style="text-align: right;">
            {{ orderResumen | thousand }}
          </b-col>

          <b-col lg="9" style="text-align: right;">
            Tarifa recaudo
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ collectionFee | thousand}}</strong>  
          </b-col>

          <b-col lg="9" style="text-align: right;">
            Descuentos
          </b-col>
          <b-col lg="3" style="text-align: right; color:green">
            <strong> 
              <span v-if="amountDiscount > 0">-</span>  
              {{ amountDiscount | thousand}}
            </strong>  
          </b-col>

          <b-col lg="9" style="text-align: right;">
            <strong>Total a pagar</strong>
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ totalCost | thousand}}</strong>
          </b-col>
          
          <hr>

          <b-col lg="9" style="text-align: right;">
            <strong>Monto recibido</strong>
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ (paymentDTO ? paymentDTO.valor : 0) | thousand }}</strong>
          </b-col>

          <b-col lg="9" style="text-align: right;">
            <strong>Crédito</strong>
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ paymentResumen.creditWon | thousand }}</strong>
          </b-col>
          <b-col lg="9" style="text-align: right;">
            <strong>Debe</strong>
          </b-col>
          <b-col lg="3" style="text-align: right;">
            <strong>{{ (paymentDTO ? paymentDTO.debe : 0) | thousand }}</strong>
          </b-col>
          <!-- <b-col lg="3" v-if="order.length > 0">
              <b-button @click="createOrder">
                Crear pago
              </b-button>
            </b-col> -->
        </b-row>     
       
        <b-row >
          <b-col style="text-align:center">
            <CBadge 
                v-if="enablePayment"
                shape="pill" 
                color="success"                
              >Datos válidos</CBadge> 
            <CBadge 
                v-else
                shape="pill" 
                color="danger"                
              >Datos NO válidos</CBadge> 
          </b-col>
        </b-row>
             
       
        

      </CCardBody>
    </CCard>
  </div>
</template>

<script>
const PENDING_PAYMENT_STATE = 1
const RECEIVED_PAYMENT_STATE = 2
export default {
  name: "resumen",
  props: ["payment", "orderResumen", "credit"],
  data() {
    return {
      clientCreditsToApply2: [],
      creditsApplied: [],
      amountDiscount: 0,
      paymentDTO: null,
      collectionFee: 0,
      paymentResumen: {
        creditWon: 0
      },
      order: null
      
    }
  },
  created() {
    this.order = this.$store.getters.order;
    this.watch()
  },
  computed: {
    totalCost() {
      if(this.clientCreditsToApply2.length > 0) {
        this.setCreditsAppliedAndAmountDiscount()
        return this.orderResumen - this.amountDiscount + this.collectionFee
      } else {        
        this.cleanCreditsAppliedAndDiscount()
        return this.orderResumen + this.collectionFee
      }
    },
    enablePayment() {
      if(!this.paymentDTO || !this.order) {
        console.log('resumen-1');
        console.log('paymentDTO: ', this.paymentDTO);
        this.blockPayment()
        return false
      } else if (this.paymentDTO.pedido_estado.id === PENDING_PAYMENT_STATE && 
        this.paymentDTO.debe === 0) {
          console.log('resumen-2');
          this.blockPayment()
          return false
      } else if(
        this.paymentDTO.pedido_estado.id === RECEIVED_PAYMENT_STATE &&
        this.paymentDTO.valor < this.totalCost ){
          console.log('resumen-3');
          this.blockPayment()
          return false
      } else {
        console.log('resumen-4');
        this.initPaymentTransaction()
        return true
      }
    }
  },
  methods: {
    watch(){
     this.$store.subscribe((mutation, state) => {
        if (mutation.type === "defClientCreditsToApply") {
          this.clientCreditsToApply2 = this.$store.getters.clientCreditsToApply;
        }
        if (mutation.type === "defPaymentDTO") {
          this.paymentDTO = this.$store.getters.paymentDTO;
          this.collectionFee = this.paymentDTO.tarifa_recaudo
          this.setCreditWon()
          
        }
        if (mutation.type === "defOrder") {
          console.log('order update from resumen');
          this.order = this.$store.getters.order;
        }
      });
    },
    removeElement: function(indexElement) {
      this.order.splice(indexElement, 1)
      this.$store.commit("defOrder", this.order)
    },

   
    setCreditsAppliedAndAmountDiscount() {
      let totalPrice = this.orderResumen;   
     
      this.cleanCreditsAppliedAndDiscount()
      this.clientCreditsToApply2.map(credit => {
        let item = {...credit}
        console.log(item);
        if(totalPrice > 0) {
          console.log('total: ', totalPrice);
          if(item.valor_disponible <= totalPrice) {
            // totalPrice -= item.valor_disponible
            this.amountDiscount += item.valor_disponible
            item.redimido = true
            item.valor_redimido += item.valor_disponible
            item.valor_disponible = 0
          } else {
            //credit.valor_disponible > totalPrice 
            item.valor_disponible -= totalPrice
            item.valor_redimido += totalPrice
            // totalPrice = 0
            this.amountDiscount += totalPrice
          }
          delete item.aplicar
        }
        this.creditsApplied.push(item)
      });
      return totalPrice
    },
    cleanCreditsAppliedAndDiscount(){
      this.creditsApplied = []
      this.amountDiscount = 0
    },
    setCreditWon(){
      if (this.paymentDTO.valor === 0 || this.paymentDTO.valor < this.totalCost){
        this.paymentResumen.creditWon = 0
        return
      }
      this.paymentResumen.creditWon = this.paymentDTO.valor - this.totalCost
    },
    blockPayment(){
      const isBlocked = true
      this.$store.commit('defBlockPayment', isBlocked);
    },
    initPaymentTransaction() {
      console.log('initPaymentTransaction');
      console.log('creditWon: ', this.paymentResumen.creditWon);

      const resumenDetail = {
        totalCost: this.totalCost,
        amountDiscount: this.amountDiscount,
        creditsApplied: this.creditsApplied,
      }
      Object.assign(this.paymentResumen, resumenDetail)
      // this.paymentResumen.totalCost = this.totalCost;
      this.$store.commit('defPaymentResumen', this.paymentResumen);
    }

  }
};
</script>

<style lang="scss" scoped></style>
